body {
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #404040;
  background: #f9f9f9;
}
.contact-us-error{
  color:red !important;
}
.inline {
  display: inline;
}

.inline-important {
  display: inline !important;
}

.inline-block {
  display: inline-block;
}
.border-top:first-child{
  border-top: 1px solid #ccc;
}
.border-bottom:not(:last-child){
  border-bottom: 1px solid #ccc;
}
.display-none {
  display: none !important;
}

.float-right {
  float: right;
}

.float-right-important {
  float: right !important;
}

.pixel {
  width: 1px;
  height: 1px;
  display: inline;
  float: right;
  position: fixed;
  bottom: 1px;
  left: 1px;
  overflow: hidden;
}

.font-weight-bold {
  font-weight: 900;
}
.font-18{
  font-size:18px;
}
@media (min-width: 768px) {
  .media768-inline-block {
    display: inline-block;
  }

  .media768-display-none {
    display: none;
  }
}

@media (max-width: 767px) {
  .media767-background-image-none-important {
    background-image: none !important;
  }
}
