$primary: #276fcf;
$gray: #525252;
$light-gray: #acacac;
$border-gray: #dcdcdc;
$green: #81b34c;

.container {
  @media (min-width: 1200px) {
    width: 95%;
    max-width: 1356px;
  }
  @media (min-width: 992px) {
    width: 95%;
  }
  @media (min-width: 750px) {
    width: 95%;
  }
}
.no {
  font-weight: 400;
}
.bold {
  font-weight: 800;
}
.underline {
  text-decoration: underline;
}

.mini-container {
  // padding: 40px;
  border-bottom: 0px solid $border-gray;
  // box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 576px) {
    padding: 0px 15px;
    border-bottom: 0px solid $border-gray;
  }

  // @media (max-width: 374px) {
  //   padding: 0px 10px;
  // }

  &__title {
    margin: 0;
    //font-weight: 400;
    color: $gray;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 1em;
    margin-top: 1em;

    @media (max-width: 990px) {
      font-size: 24px;
      line-height: 28px;
    }

    span {
      //font-weight: 400;
    }
  }

  &__title_bold {
    margin: 0;
    font-weight: 900;
    color: $gray;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 1em;
    margin-top: 1em;

    @media (max-width: 990px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 50px;
    
    &--expander {
      display: flex;
      align-items: center;
      color: #81b34c;
      cursor: pointer;
      font-weight: 500;
      text-decoration: uppercase;
      text-decoration: underline;

      img {
        max-width: 100%;
        margin-left: 12px;
      }
    }
  }
}

.mini-dwb {
  border: 1px solid #acacac;
  border-radius: 6px;
  background: #fff;
  padding: 0 25px;
  margin: 0;
  margin-top: 24px;
  margin-bottom: 24px;

  max-width: 1440px;


  @media (max-width: 576px) {
    padding: 0 20px;
  }

  &-header {
    display: flex;
    position: relative;
    padding: 20px 0 16px;
    border-bottom: 1px solid #acacac;

    @media (max-width: 990px) {
      padding: 32px 0 12px;
    }

    @media (max-width: 576px) {
      padding: 12px 0;
    }
  }
  &-badge {
    position: absolute;
    right: -14px;
    top: 22px;
    width: 96px;
    height: 96px;

    img {
      width: 100%;
    }

    @media (max-width: 990px) {
      width: 64px;
      height: 64px;
      top: 38px;
    }

    @media (max-width: 576px) {
      width: 48px;
      height: 48px;
      top: 20px;
      right: -10px;
    }
  }

  &-title {
    font-size: 32px;
    color:#525252;
    font-weight: 900;
    margin: 0;

    @media (max-width: 990px) {
      font-size: 24px;
    }

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
}

.mini-checkbox {
  width: 100%;
  height: 46px;
  display: flex;
  margin-bottom: 80px;
  display: flex;
  justify-content: right;

  @media (max-width: 834px) {
    margin-bottom: 60px;
  }
  @media (max-width: 576px) {
    margin-bottom: 30px;
  }

  // .left {
  //   width: 90%;
  //   @media (max-width: 990px) {
  //     width: 75%;
  //   }
  //   @media (max-width: 576px) {
  //     width: 65%;
  //   }
  //   @media (max-width: 325px) {
  //     width: 45%;
  //   }

  // }

  .right {
    display: flex;
    width: 293px;
    height: 76px;
    justify-content: center;
    align-items: center;
    border: 1px solid #ACACAC;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;

    input[type="checkbox"] {
      box-shadow: 2px 2px 2px 0px #80808080;
      width: 20px;
      height: 20px;
      @media (max-width: 576px) {
        width: 14px;
        height: 14px;
      }
    }

    @media (max-width: 834px) {
      height: 71px;
      width: 255px;
    }

    @media (max-width: 576px) {
      width: 155px;
      height: 46px;
    }

    label {
      padding-top: 10px;
      //padding-right: 10px;
      padding-left: 10px;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 900;
      line-height: 28.13px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #525252;

      @media (max-width: 834px) {
        font-size: 20px;
      }
      @media (max-width: 576px) {
        font-size: 12px;
        line-height: 14.06px;
      }
    }
  }

}

.mini-navigation {
  text-align: center;
  margin-top: 40px;

  @media (max-width: 990px) {
    margin-top: 32px;
  }

  @media (max-width: 576px) {
    margin-top: 24px;
  }
}

.mini-ads {
  iframe {
    border: 1px solid $light-gray;
    border-radius: 6px;
    border-style: solid !important;
  }
}

.aka-card-content {
  @media (max-width: 576px) {
    .col-xs-6 {
      width: 100%;
    }
  }

  .aka-card {
    padding-top: 40px;
    padding-left: 16px;
    padding-bottom: 20px;
    // padding-right: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  
    @media (max-width: 576px) {
      padding-bottom: 10px;
      padding-top: 20px;
      margin-bottom: 0px;
    }
  
    &__index {
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 16px;
      line-height: 18.75px;
      background: #acacac;
      font-weight: 900;
  
      @media (max-width: 576px) {
        min-width: 30px;
        min-height: 30px;
        width: 30px;
        height: 30px;
      }
    }
  
    &__name {
      margin-left: 16px;
      color: #276FCF;
      font-weight: 900;
      font-size: 18px;
      line-height: 21px;
  
      @media (max-width: 576px) {
        margin-left: 12px;
        font-size: 12px;
        line-height: 14px;
        width: 70%;
      }
    }
  }
}


.names {
  display: flex;
  flex-flow: row wrap;

  &:before {
    display: block;
  }
}

.mini-box {
  border: 1px solid $light-gray;
  background-color: #F9F9F9;
  border-radius: 6px;
  margin-bottom: 36px;

  &.dark {
    border-color: $gray;
  }

  &__body {
    padding: 0px 20px;
    // padding: 60px 120px;
    color: $gray;

    @media (max-width: 990px) {
      padding: 0px 20px;
    }

    @media (max-width: 576px) {
      padding: 0px 10px;
    }

    &--row {
      border-bottom: 1px solid $border-gray;
      // margin-left: -120px;
      // margin-right: -120px;
      // padding-left: 120px;
      // padding-right: 120px;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 20px;
      padding-bottom: 20px;

      @media (max-width: 990px) {
        margin-left: -40px;
        margin-right: -40px;
        padding-left: 40px;
        padding-right: 40px;
      }

      @media (max-width: 576px) {
        margin-left: -18px;
        margin-right: -18px;
        padding-left: 28px;
        padding-right: 28px;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    &--category {
      margin: 0;
      margin-bottom: 12px;

      @media (max-width: 576px) {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }

  &__detail {
    font-size: 18px;
    line-height: 24px;

    &--label {
      margin: 12px 0 8px;

      @media (max-width: 990px) {
        margin: 6px 0 4px;
      }
    }

    &--value {
      &.primary {
        color: $primary;
      }

      font-weight: 900;
      margin: 0;
      margin-bottom: 12px;

      @media (max-width: 990px) {
        margin-bottom: 6px;
      }
    }

    &--label,
    &--value {
      font-size: 18px;

      @media (max-width: 990px) {
        font-size: 16px;
      }

      @media (max-width: 576px) {
        font-size: 10px;
      }
    }
  }
}

.mini-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column;
    justify-items: flex-start;
  }

  &__lhs {
    width: 400px;

    &--title {
      color: $primary;
      font-size-adjust: 32px;
      line-height: 38px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 16px;
    }

    &--desc {
      font-size: 16px;
      line-height: 18px;
      color: $gray;
    }

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &__rhs {
    img {
      max-width: 350%;
    }
  }
}



.scroll-to-top {
  .btn-scroll-to-top {
    cursor: pointer;
    padding: 15px 15px 5px 15px;
    border-radius: 2%;
    font-size: 18px;
    background-color: transparent;
    border: none;
    outline: none;

    @media screen and (max-width: 834px) {
      padding: 10px 10px 3px 10px;
    }
  }

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    color: #276fcf;
    text-align: center;
  }

  display: none;
  position: fixed;
  bottom: 2%;
  right: 0px;
  z-index: 999;
  scroll-behavior: smooth;
  flex-direction: column;

  @media screen and (max-width: 834px) {
    right: 0px;
  }

  @media screen and (max-width: 534px) {
    right: 0px;
  }
}

.report-line {
  border-bottom: 1px solid #dcdcdc;
  box-shadow: 0px 1px 4px 0px #00000033;
//  margin-top: 5px;
}

.empty-line {
  margin-top: 30px;
}