/* Shared marketing and member styles */

body {
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #404040;
  background: #f9f9f9;
}

a {
  color: #4851c0;
  transition: color 0.5s ease;
}

*:focus {
  outline: none;
}

textarea:focus,
input:focus {
  outline: none;
}

.tooltip-inner {
  border-radius: 0;
}

.btn-default:hover {
  color: #fff;
  background-color: #474dad;
  border-color: #474dad;
}

.btn-lg,
.btn-group-lg>.btn {
  border-radius: 0;
}

.btn-primary {
  font-size: 16px;
  padding: 10px 20px 8px;
  border-radius: 2px;
  text-transform: uppercase;
  letter-spacing: .05em;
  font-weight: 700;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, .2);
  border-color: transparent;
  transition: all 0.5s ease;
  background: #76b509;
  color: #fff;
}

.modify-search .btn-primary {
  font-size: 13px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, .5);
  border-color: transparent;
  outline: none;
}

.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.btn-primary.focus,
.btn-primary:active.focus,
.btn-primary.active.focus {
  border-color: transparent;
  outline: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus {
  background: #76b509;
  color: #fff;
  border-color: transparent;
}

.btn-default {
  padding: 10px 20px 8px;
  border-radius: 2px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, .2);
  transition: all 0.5s ease;
}

.btn-success {
  font-size: 13px;
  padding: 10px 20px 8px;
  border-radius: 2px;
  text-transform: uppercase;
  letter-spacing: .05em;
  font-weight: 700;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, .2);
  border-color: transparent;
  transition: all 0.5s ease;
  background: #474dad;
  color: #fff;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success:active:focus {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, .5);
  background: #474dad;
  border-color: transparent;
  outline: none;
}

.btn-link {
  color: #4851c0;
}

.btn span.glyphicons {
  margin: -4px -14px -4px -6px;
}

img {
  max-width: 100%;
}

.well {
  background-color: #f9f9f9;
  border-radius: 0;
}

label {
  font-weight: 500;
}

.has-error .form-control {
  border-color: red;
}

span.has-error {
  color: red;
  font-size: 12px;
}

textarea.has-error {
  color: red;
  font-size: 12px;
  border-color: red;
}

.has-error .checkbox label {
  color: red !important;
}

.has-success .form-control {
  border-color: #76b509;
}

.has-feedback label~.form-control-feedback {
  top: 28px;
}

.mobile-credit-cards {
  width: 135px;
}

input.form-control.card.allcards,
input.form-control.card.visa,
input.form-control.card.mastercard,
input.form-control.card.amex,
input.form-control.card.discover {
  background-repeat: no-repeat;
  background-size: 48px auto;
  background-position: right center;
}

input.form-control.card.allcards {
  background-size: 150px auto;
  background-image: url(/assets/common/pr1/img/creditcards.svg);
}

input.form-control.card.visa {
  background-image: url(/assets/common/pr1/img/visa.svg);
}

input.form-control.card.mastercard {
  background-image: url(/assets/common/pr1/img/mastercard.svg);
}

input.form-control.card.amex {
  background-image: url(/assets/common/pr1/img/amex.svg);
}

input.form-control.card.discover {
  background-image: url(/assets/common/pr1/img/discover.svg);
}

label span.glyphicons {
  margin: -4px 0 -3px -8px;
  cursor: pointer;
  font-size: 11px;
}

textarea {
  resize: vertical;
}

.navbar {
  background: #474dad;
  /* background: transparent; */
  margin-bottom: 0;
  border: 0;
}

.navbar.navbar-footer {
  overflow: hidden;
  border-radius: 0px;
}

.navbar-header {
  margin-left: 0 !important;
}

.navbar-brand {
  margin-top: 0 !important;
  padding-top: 0;
}

.navbar-brand>img {
  width: 170px;
}

.navbar-nav {
  margin: 12px 3px 0;
}

.select-wrap select {
  border: 0;
  background: url(/assets/common/pr1/img/down-arrow.svg) no-repeat right center;
  background-color: #fff;
  background-size: 14px auto;
  width: 100%;
  display: block;
  -webkit-appearance: none;
  padding: 0;
  margin-top: 2px;
}

.select-wrap select:focus {
  outline: none;
}

.form-control {
  border-radius: 0;
  box-sizing: border-box;
  border: 2px solid transparent;
  -webkit-appearance: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

}

.form-control:focus {

  border: 3px solid rgb(73, 83, 188) !important;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.dropdown-menu {
  border-radius: 0;
}

.tools {
  padding: 5px 0 3px;
}

.tools .text-right {
  padding: 0;
}

.tools .dropdown.modify-search .dropdown-menu {
  width: 170px;
  padding: 10px;
}

.tools .dropdown.modify-search .dropdown-menu .form-group {
  margin-bottom: -1px;
}

.tools .dropdown.modify-search .dropdown-menu .select-wrap {
  padding: 7px 13px 0;
  margin-bottom: 10px;
}

.tools .dropdown.modify-search .dropdown-menu .btn {
  width: 100%;
}

.results-list ul {
  margin: 0;
  padding: 0;
}

.results-list ul li {
  list-style-type: none;
  margin-bottom: 8px;
}

.results-list ul li a.result {
  display: inline-block;
  background: #fff;
  border: 1px solid #dddddd;
  padding: 20px 0 16px;
  color: #404040;
  cursor: pointer;
}

.results-list ul li a.result:hover {
  background: #f4f5ff;
  text-decoration: none;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, .1);
}

.results-list ul li a.result.highlight {
  background: #feffed;
}

.results-list ul li a.result.highlight:hover {
  background: #f4f5ff;
}

.results-list ul li a.result p {
  color: #666;
  font-size: 13px;
}

.results-list .person img {
  border-radius: 50%;
  border: 1px solid #ccc;
  width: 50px;
  height: 50px;
  min-width: 50px;
}

.results-list li h3 {
  font-size: 21px;
  margin-bottom: 4px;
  margin-top: 0;
}

.results-list li h4 {
  font-size: 16px;
  font-weight: 400;
  color: #727272;
  margin: 0 0 10px 0;
}

.results-list li h5 {
  font-size: 13px;
  font-weight: 400;
  color: #666;
  margin: 10px 0 10px;
  line-height: inherit;
}

.results-list li .verified img {
  width: 80px;
}

#search-result #loadMore a {
  width: 300px;
  margin: 20px 0;
  padding: 15px 0;
  font-size: 18px;
  font-weight: bold;
}

.dropdown .btn-default,
.dropdown .btn-default:hover,
.dropdown .btn-default:focus,
.dropdown .btn-default:active {
  border-color: transparent !important;
  background: transparent !important;
  outline: none;
  box-shadow: none;
  color: #666;
  border: none;
}

.open>.dropdown-toggle.btn-default {
  background-image: none;
  background: transparent;
  border: transparent;
}

.form-group .help-block {
  font-size: 12px;
  color: #727272;
}

.alert {
  border-radius: 0;
}

.alert .glyphicons {
  display: block;
  text-align: center;
  font-size: 24px;
  margin-top: -5px;
}

.alert-success {
  background: #e0efc8;
  color: #527e06;
}

.alert-success a {
  color: #527e06;
  text-decoration: underline;
}

.modal-content {
  border-radius: 0;
}

.modal-header {
  background: #efefef;
}

.modal.offer .modal-content {
  padding-top: 20px;
}

.modal.offer h1 {
  margin-top: 0;
}

.modal.offer h4 {
  color: #666;
  line-height: 1.5em;
}

.modal.offer h1 span {
  color: #474dad;
}

.modal.offer .btn {
  margin: 15px 0 40px;
}

.has-feedback .form-control {
  padding-right: 0;
}

/* Media query styles */

@media (max-width: 767px) {
  .navbar-brand>img {
    width: 155px;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>li>a {
    color: #fff;
  }

  .navbar-brand {
    padding: 1px 5px;
  }

  .results-list li .verified img {
    width: 80px;
  }

  .dropdown .btn-default {
    padding-left: 0px;
    padding-right: 12px;
    font-size: 12px;
  }

  label {
    font-size: 10px;
  }

  input.form-control.card.visa,
  input.form-control.card.mastercard,
  input.form-control.card.amex,
  input.form-control.card.discover,
  input.form-control.card.allcards {
    background-image: none;
  }
}

/* Report Loader v.2 */
#report-review.v2 .progress {
  border-radius: 0;
  height: 35px;
}

#report-review.v2 .progress-bar {
  background-color: #76b509;
  padding: 9px 5px 0 0;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
}

#report-review.v2 .content-box {
  min-height: 330px;
}

#report-review.v2 .loader-header {
  margin-top: 0;
}

#report-review.v2 .content-box .loader-content .well {
  border: none;
  background-color: inherit;
}

#report-review.v2 .steps {
  text-align: center;
  margin-top: 0;
}

#report-review.v2 .steps .circle {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 50%;
  margin: 5px;
  background: #ccc;
}

#report-review.v2 .steps .circle.active {
  background: #76b509;
}

#report-review.v2 .steps hr {
  margin-top: -15px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid #ccc;
  width: 40px;
}

#report-review.v2 .loader-header p {
  color: #727272;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: .05em;
}

#report-review.v2 .loader-header h1 {
  font-weight: 300;
}

#report-review.v2 .loader-content h2 {
  font-size: 12px;
  margin-bottom: 20px;
  font-weight: 300;
  color: #727272;
}

#report-review.v2 .loader-content h3 {
  font-size: 16px;
  font-weight: 300;
  margin-top: 4px;
}

#report-review.v2 .loader-content p {
  font-size: 12px;
  line-height: 1.8em;
}

#report-review.v2 .loader-content img {
  max-width: 60px;
  float: right;
  margin-right: -10px;
}

#report-review.v2 .loader-content ul {
  margin: 0;
  list-style-type: none;
  padding: 0;
}

#report-review.v2 .loader-content ul li {
  font-size: 12px;
  color: #666;
  display: inline-block;
}

#report-review.v2 .loader-content ul li .glyphicons {
  color: #727272;
  margin: -4px -5px 4px -3px;
}

#report-review.v2 .loader-content ul li .glyphicons.success {
  color: #76b509;
}

.icon-social {
  background-image: url(/assets/common/pr1/img/icons/social-icons-28x28.svg) !important;
  background-position: -392px 0px !important;
  width: 56px;
  height: 56px;
  background-size: 2576px 168px !important;
}

.icon-social.linkedin {
  background-position: -0px 0px !important;
}

.icon-social.twitter {
  background-position: -56px 0px !important;
}

.icon-social.facebook {
  background-position: -112px 0px !important;
}

.icon-social.angellist {
  background-position: -168px 0px !important;
}

.icon-social.flickr {
  background-position: -224px 0px !important;
}

.icon-social.google {
  background-position: -280px 0px !important;
}

.icon-social.pinterest {
  background-position: -336px 0px !important;
}

.icon-social.link {
  background-position: -392px 0px !important;
}

.icon-social.job {
  background-position: -448px 0px !important;
}

.icon-social.location {
  background-position: -504px 0px !important;
}

.icon-social.aboutme {
  background-position: -560px 0px !important;
}

.icon-social.quora {
  background-position: -616px 0px !important;
}

.icon-social.yelp {
  background-position: -672px 0px !important;
}

.icon-social.youtube {
  background-position: -728px 0px !important;
}

.icon-social.instagram {
  background-position: -784px 0px !important;
}

.icon-social.github {
  background-position: -840px 0px !important;
}

.icon-social.behance {
  background-position: -896px 0px !important;
}

.icon-social.vimeo {
  background-position: -952px 0px !important;
}

.icon-social.skype {
  background-position: -1008px 0px !important;
}

.icon-social.klout {
  background-position: -1064px 0px !important;
}

.icon-social.dribbble {
  background-position: -1120px 0px !important;
}

.icon-social.goodreads {
  background-position: -1176px 0px !important;
}

.icon-social.reddit {
  background-position: -1232px 0px !important;
}

.icon-social.foursquare {
  background-position: -1288px 0px !important;
}

.icon-social.tumblr {
  background-position: -1344px 0px !important;
}

.icon-social.soundcloud {
  background-position: -1400px 0px !important;
}

.icon-social.name {
  background-position: -1456px 0px !important;
}

.icon-social.crunchbase {
  background-position: -1512px 0px !important;
}

.icon-social.phone {
  background-position: -1568px 0px !important;
}

.icon-social.note {
  background-position: -1624px 0px !important;
}

.icon-social.mail {
  background-position: -1680px 0px !important;
}

.icon-social.birthday {
  background-position: -1736px 0px !important;
}

.icon-social.page {
  background-position: -1792px 0px !important;
}

.icon-social.heart {
  background-position: -1848px 0px !important;
}

.icon-social.conversation {
  background-position: -1904px 0px !important;
}

.icon-social.yahoo {
  background-position: -1960px 0px !important;
}

.icon-social.hangouts {
  background-position: -2016px 0px !important;
}

.icon-social.calendar {
  background-position: -2072px 0px !important;
}

.icon-social.xing {
  background-position: -2128px 0px !important;
}

.icon-social.gravatar {
  background-position: -2184px 0px !important;
}

.icon-social.picasa {
  background-position: -2240px 0px !important;
}

.icon-social.qq {
  background-position: -2296px 0px !important;
}

.icon-social.medium {
  background-position: -2352px 0px !important;
}

.icon-social.hackernews {
  background-position: -2408px 0px !important;
}

.icon-social.keybase {
  background-position: -2464px 0px !important;
}

.icon-social.owler {
  background-position: -2520px 0px !important;
}