/* MPI 3DS */
.mpi-3ds-container {
  position: fixed;
  z-index: 101;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
}

/* Snackbar */
.snackbars {
  position: fixed;
  z-index: 100;
  text-align: center;
}

#snackbars-top {
  top: 70px;
  margin-left: 10%;
  width: 80%;
}

#snackbars-bottom {
  bottom: 30px;
  margin-left: 30%;
  width: 40%;
}

.snackbar {
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  transition: all ease;
}

.snackbar.ready {
  opacity: 0;
}

.snackbar.show {
  opacity: 1;
}

.snackbar.done {
  opacity: 0;
}

.snackbar-top {
}

.snackbar-bottom {
}

.snackbar-top.ready {
  margin-top: -62px;
  margin-bottom: 10px;
}

.snackbar-top.show,
.snackbar-top.done {
  margin-top: 0px;
  margin-bottom: 10px;
}

.snackbar-bottom.ready {
  margin-bottom: -62px;
  margin-top: 10px;
}

.snackbar-bottom.show,
.snackbar-bottom.done {
  margin-bottom: 0px;
  margin-top: 10px;
}

.snackbar a {
  color: #4be3ff;
}

/* Report Review Page */
/* make box size normal instead of huge */
#report-review .loader-content[_ngcontent-c3] .well[_ngcontent-c3] {
  min-height: 250px;
}

#report-review .content-box {
  padding-bottom: 0px;
}

#report-review .featured-on, .customers-saying {
  padding-top: 0em;
}

/* make tags wrap correctly and be red */
.content-box .loader-content .tags {
  padding: 0 0px 0 0;
}

.content-box .loader-content .tags .animated {
  color: red;
}

/* allow for step headers */
.steps-form {
  display: table;
  width: 100%;
  position: relative;
}

.steps-form .steps-row {
  display: table-row;
}

.steps-form .steps-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.steps-form .steps-row .steps-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.steps-form .steps-row .steps-step p {
  margin-top: 5px;
}

.steps-form .steps-row .steps-step .btn-disabled {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  background-color: #666;
}

.steps-form .steps-row .steps-step .btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  margin-top: 0;
}

.steps-form .steps-row .steps-step .btn {
  color: #fff !important;
}

.steps-form .steps-row .steps-step .btn-active {
  background-color: #474dad;
}

.steps-form .steps-row .steps-step .btn-done {
  background-color: #76b509;
  opacity: 1;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

/* All pages: Animated Glyphs */

.glyphicons.fast-right-spinner {
  -webkit-animation: glyphicons-spin-r 1s infinite linear;
  animation: glyphicons-spin-r 1s infinite linear;
}

.glyphicons.normal-right-spinner {
  -webkit-animation: glyphicons-spin-r 2s infinite linear;
  animation: glyphicons-spin-r 2s infinite linear;
}

.glyphicons.slow-right-spinner {
  -webkit-animation: glyphicons-spin-r 3s infinite linear;
  animation: glyphicons-spin-r 3s infinite linear;
}

.glyphicons.fast-left-spinner {
  -webkit-animation: glyphicons-spin-l 1s infinite linear;
  animation: glyphicons-spin-l 1s infinite linear;
}

.glyphicons.normal-left-spinner {
  -webkit-animation: glyphicons-spin-l 2s infinite linear;
  animation: glyphicons-spin-l 2s infinite linear;
}

.glyphicons.slow-left-spinner {
  -webkit-animation: glyphicons-spin-l 3s infinite linear;
  animation: glyphicons-spin-l 3s infinite linear;
}

@-webkit-keyframes glyphicons-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes glyphicons-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes glyphicons-spin-l {
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes glyphicons-spin-l {
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

/*
 Sales Landing
 */
#landing-lightbox .modal-header {
  background: #464dac;
}

#landing-lightbox .modal-title {
  color: #fff;
}

/*
 Sales SRP
 */
.search-results .dropdown-toggle.btn {
  font-size: 16px;
  font-weight: bold;
}

#bottomModifySearch {
  margin: 10px -30px 10px -30px;
  background: #feffed;
  border: 1px solid #dddddd;
  padding: 15px 0 0 0;
}

#bottomModifySearch .select-wrap select {
  margin-top: 0;
}

#bottomModifySearch button {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  #bottomModifySearch button {
    margin-bottom: 15px;
  }
}

/* MISC */
span.search.target.name {
  color: #76b509;
}

#signup input[type=checkbox] {
  width: 16px;
  height: 16px;
}

.modal-footer {
  text-align: center;
}
