$white: #FFFFFF;
$black: #000000;

$color-primary: #525252;
$color-primary-light: #acacac;
$color-secondary: #ACACAC;
$color-blue: #276FCF;
$color-brand: #464FA7;
$color-gold: #EDBA71;
$color-green: #81B34C;
$color-red: #E54747;

$back-primary: #525252;
$back-secondary: #ACACAC;

$back-gold: #E89F10;
$back-green: #81B34C;
$back-blue: #276FCF;

$border-primary: #525252;
$border-secondary: #DCDCDC;
$border-blue: #276FCF;

$not-available: #999999;
