/* Public view styles */

.photo-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-attachment: fixed;
}

.photo-wrapper.home {
  background-image: url(/assets/common/pr1/img/background1.jpg);
}

.photo-wrapper .navbar {
  background: transparent;
  margin-bottom: 0;
  border-color: transparent;
}

.navbar-default .navbar-nav>li>a.btn {
  padding: 5px 10px;
  border: 1px solid rgba(255, 255, 255, .3);
  box-shadow: none;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .045em;
  font-weight: 500;
  font-size: 12px;
}

.jumbotron {
  background-color: transparent;
  color: #fff;
  margin-bottom: 0;
}

.jumbotron h1 {
  font-size: 44px;
  font-weight: 500;
  line-height: 1.1;
  margin: 0 auto 10px;
  max-width: 90%;
}

.jumbotron h3 {
  font-size: 17px;
  font-weight: 400;
  opacity: .6;
  margin: 0 auto 30px;
  max-width: 80%;
}

.jumbotron .form-inline .form-control {
  margin-top: -1px;
  height: 50px;
  font-size: 17px;
}

.jumbotron .select-wrap select {
  margin-top: 6px;
}

.search-wrapper {
  background: rgba(255, 255, 255, .4);
  padding: 20px 30px 25px;
  box-shadow: 0px 9px 18px rgba(0, 0, 0, .22);
  display: inline-block;
}

.search-wrapper.progressor {
  width: 100%;
}

.search-wrapper.progressor .use {
  margin: -10px 0 -13px -15px;
}

.search-wrapper.progressor .content-box {
  padding: 0;
}

.search-wrapper.progressor .content-box .progress {
  margin-bottom: 0;
}

.form-wrapper {
  display: block;
}

.form-wrapper.phone .form-control {
  font-size: 28px;
}

.search-wrapper p {
  font-size: 15px;
  font-weight: 400;
  opacity: .8;
  text-align: left;
}

.search-wrapper button {
  padding: 14px 30px;
  font-size: 14px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
}

.search-wrapper .form-control:focus {
  border-color: #4851c0;
  border-width: 3px;
}

.search-wrapper button,
.search-wrapper .form-group {
  box-shadow: 0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.4);
}


.seals {
  padding: 5px 20px 0;
  text-align: right;
  margin-bottom: -20px;
}

.seals .seal img {
  height: 30px;
}

.seals .seal.mcafree img {
  height: 25px;
}

.search-counter {
  height: 55px;
  color: white;
  opacity: 0.7;
  text-align: center;
  padding: 10px;
  margin-top: -10px;
  background-color: #442b2b;
}

.search-counter .count {
  font-size: 24px;
  font-weight: bold;
  margin-right: 5px;
}

.navbar .logos img {
  width: 53px;
  margin-top: 12px;
  margin-right: 7px;
  background: rgba(255, 255, 255, .9);
  padding: 2px 3px 1px;
}

.form-wrapper.phone {
  display: inline-block;
}

.jumbotron.phone .form-inline .form-control {
  height: 60px;
  font-size: 30px;
}

.search-wrapper.phone button {
  padding: 19px 30px;
}

.phone-results.content-box {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.phone-results .dl-large {
  font-size: 16px;
}

.phone-results .dl-large dd {
  margin-bottom: 8px;
}

.phone-results-lower {
  margin-top: 30px;
}

.phone-results-lower ul {
  padding: 0 0 0 15px;
}

/* Featured on and Customers saying sections */

.featured-on,
.customers-saying {
  padding-top: 5em;
  padding-bottom: 3em;
  background: #f9f9f9;
}

.featured-on h2,
.customers-saying h2 {
  opacity: .7;
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 40px;
}

.featured-on img {
  max-height: 30px;
  opacity: .4;
  margin: 0 10px 30px;
  vertical-align: middle;
}

.customers-saying {
  background: #464dac;
  box-shadow: inset 0px 110px 160px rgba(105, 31, 116, .3);
}

.customers-saying h2 {
  color: #ffffff;
  opacity: .7;
}

blockquote {
  border: 0;
}

blockquote p {
  background: #9fa4eb;
  position: relative;
  font-size: 15px;
  padding: 20px;
  color: #ffffff;
  text-align: center;
}

blockquote p img {
  width: 30px;
  position: absolute;
  top: -7px;
  left: -20px;
  opacity: .8;
}

blockquote p:after {
  top: 100%;
  left: 27px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(244, 244, 244, 0);
  border-top-color: #9fa4eb;
  border-width: 8px;
  margin-left: -8px;
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: none;
}

blockquote footer,
blockquote small,
blockquote .small {
  padding-top: 6px;
  padding-left: 10px;
  color: #ffffff;
  opacity: .4;
}

.footer {
  padding-top: 5em;
  padding-bottom: 7em;
  transition: all 0.5s ease;
  opacity: .5;
  border-top: 1px solid #e1e1e1;
}

.footer:hover {
  opacity: 1;
}

.footer ul {
  list-style-type: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer .secure-logos {
  margin: 20px 0;
}

.footer .secure-logos img {
  max-width: 80px;
  margin-right: 20px;
}

/* Custom content styles */

.content-wrapper {
  background: #f9f9f9;
  padding-bottom: 40px;
}

.content-box {
  background: #fff;
  border: 1px solid #dddddd;
  padding: 0;
  padding-bottom: 70px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, .04);
}

.content-box.padded {
  padding: 30px;
}

.loader-header {
  margin-top: 20px;
  margin-bottom: 20px;
}

.loader-header h1 {
  margin-top: 0;
  font-size: 30px;
}

.loader-header h1 span.glyphicons {
  margin-top: -6px;
  margin-right: -8px;
  color: #76b509;
}

.loader-header p {
  color: #727272;
  font-weight: 400;
  font-size: 16px;
}

.loader-header p span.billing-details-mobile a {
  font-size: 90%;
  color: #727272;
  text-decoration: underline;
  cursor: pointer;
}

.phone-no-wrap {
  white-space: nowrap;
}

.loader-content .left-col {
  padding-left: 30px;
}

/* .content-box p {
  color: #666;
} */

.content-box h4 {
  font-size: 21px;
  padding: 0 30px;
  margin: 20px auto 18px;
  max-width: 500px;
}

.content-box .progress {
  border-radius: 0;
  height: 35px;
  margin-bottom: 35px;
}

.content-box .progress-bar {
  background-color: #76b509;
  padding: 9px 0px 0 0;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
}

.content-box .loader-content {
  margin: 0 15px;
}

.content-box .loader-content .tags {
  padding: 0 20px 0 0;
}

.content-box .loader-content .tags li {
  background: #fff;
  padding: 4px 8px 1px 2px;
  font-size: 13px;
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #dddddd;
  margin: 0 0px 5px;
  color: #727272;
}

.content-box .loader-content .tags li span {
  margin-top: -4px;
}

.content-box .loader-content .loader-list {
  margin: 0 0 10px;
  padding: 0;
}

.content-box .loader-content .loader-list li {
  display: inline-block;
  width: 48%;
  vertical-align: top;
}

.content-box .loader-content .checkbox {
  margin-top: 0;
  margin-bottom: 6px;
}

.content-box .loader-content .checkbox span {
  color: #dcb103;
  margin-top: -4px;
  margin-left: -8px;
  margin-right: -8px;
}

.remove-my-info {
  margin-bottom: 20px;
}

.remove-my-info .form-control {
  margin-right: -5px;
  width: 120px;
  height: 38px;
  vertical-align: top;
}

/* Search results styles */

.search-results .header {
  padding: 20px 30px 0;
}

.search-results .header h1 {
  font-size: 30px;
}

.search-results .header h4 {
  color: #727272;
  font-weight: 400;
  font-size: 16px;
}

/* Signup / contact styles */

.signup .content-box {
  padding: 20px;
}

.signup form h6 {
  text-transform: uppercase;
  color: #727272;
  letter-spacing: .025em;
}

.signup .form-control {
  -webkit-appearance: none;
  box-shadow: 0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.4);
  ;
  -webkit-box-shadow: 0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.4);
  ;
}

.signup .form-control:focus {
  border-color: #4851c0;
  border-width: 3px;
}

.signup .checkbox label {
  font-size: 12px;
  color: #727272;
}

.signup .side-column {
  padding: 10px 30px;
}

.signup select option:first-child {
  display: none;
}

.signup select.empty {
  color: #999999;
}

.signup .side-column .blurry-person {
  text-align: center;
  margin-bottom: 29px;
  background: #fff;
  padding: 10px 10px 2px;
  border-radius: 10px;
}

.signup .side-column .blurry-person img {
  height: 60px;
  width: 60px;
}

.signup .side-column h4 {
  margin-bottom: 20px;
  color: #666;
}

.signup .side-column h5 {
  color: #818181;
  margin-bottom: 3px;
  font-size: 13px;
}

.signup .side-column h5 span.glyphicons {
  margin: -8px -5px 0 -10px;
  font-size: 16px;
}

.signup .side-column p,
.signup .side-column ul {
  color: #818181;
  margin-bottom: 20px;
  font-size: 13px;
}

.signup .side-column ul {
  padding-left: 15px;
}

.blank {
  max-width: 100px;
  display: inline-block;
}

.blank img {
  height: 17px;
}

/* Login styles */
#login {
  background: #f9f9f9;
}

#login h1 {
  margin-top: 50px;
}

#login .content-box {
  padding: 20px;
}

#login .checkbox {
  margin-top: 0;
}

#login .checkbox label {
  font-size: 13px;
  color: #727272;
}

#login .navbar-brand {
  width: 100%;
  margin: 0 !important;
}

#login .navbar-brand img {
  margin: 0 auto;
}

#login .btn {
  width: 100%;
}

#login p a {
  font-size: 13px;
  color: #727272;
  text-decoration: underline;
}

/* Report Sample styles */

.report-mockup {
  position: relative;
}

.report-mockup .scrolling-report {
  background-size: cover;
  height: 512px;
  bottom: -20px;
  /* width: 100%; */
  left: 0;
  box-shadow: 0 1px 3px 0 rgba(64, 70, 77, 0.3);
  animation: scrolling-report 90s infinite 3s forwards;
  border: 1px solid #e8e8e8;
}

.report-mockup .scrolling-report.mobile {
  background-image: url(/assets/common/pr1/img/mobile-large.jpg);
}

.report-mockup .scrolling-report.desktop {
  background-image: url(/assets/common/pr1/img/desktop-large.jpg);
}

#sup .content-box.reviews {
  min-height: 250px;
}

@keyframes scrolling-report {
  0% {
    background-position-y: 0
  }

  5% {
    background-position-y: 5%
  }

  10% {
    background-position-y: 10%
  }

  15% {
    background-position-y: 15%
  }

  20% {
    background-position-y: 20%
  }

  25% {
    background-position-y: 25%
  }

  30% {
    background-position-y: 30%
  }

  35% {
    background-position-y: 35%
  }

  40% {
    background-position-y: 40%
  }

  45% {
    background-position-y: 45%
  }

  50% {
    background-position-y: 50%
  }

  55% {
    background-position-y: 55%
  }

  60% {
    background-position-y: 60%
  }

  65% {
    background-position-y: 65%
  }

  70% {
    background-position-y: 70%
  }

  75% {
    background-position-y: 75%
  }

  80% {
    background-position-y: 80%
  }

  85% {
    background-position-y: 85%
  }

  90% {
    background-position-y: 90%
  }

  95% {
    background-position-y: 0
  }

  100% {
    background-position-y: 0
  }
}

.sample-report-description {
  text-align: left;
  margin-bottom: 30px;
  margin-top: 50px;
}

.sample-report-description h2 {
  opacity: .7;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.sample-report-description p {
  margin-bottom: 20px;
}

.sample-report-description ul {
  margin-bottom: 20px;
}

/* Media query styles */

@media (min-width: 768px) {
  .photo-wrapper .navbar {
    padding: 20px 10px 0;
  }

  .photo-wrapper .navbar .navbar-brand>img {
    width: 190px;
  }

  .jumbotron {
    padding-top: 10em;
    padding-bottom: 14em;
  }

  .sidebar {
    padding-left: 30px;
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  .photo-wrapper .navbar {
    background: transparent;
  }

  .photo-wrapper {
    background-size: auto 100%;
    background-position-x: right;
    background-attachment: inherit;
  }

  .photo-wrapper .navbar-nav {
    margin: 15px 5px 0;
    opacity: 0.6;
  }

  .photo-wrapper .navbar-brand {
    padding: 5px 5px 0;
    margin-left: -10px;
  }

  .jumbotron h1 {
    font-size: 24px;
    max-width: 95%;
  }

  .jumbotron h3 {
    font-size: 15px;
    max-width: 95%;
  }

  .search-wrapper p {
    font-size: 13px;
  }

  .search-results .header {
    padding: 14px 20px 0;
  }

  .loader-header {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .search-results .header h1,
  .loader-header h1,
  #login h1 {
    font-size: 19px;
    margin-top: 10px;
  }

  .search-results .header h4 {
    font-size: 14px;
  }

  .signup .form-group button {
    width: 100%;
  }

  .phone-results .dl-large {
    text-align: center;
  }

  .phone-results .map {
    margin-top: 20px;
  }

  .remove-my-info .form-control {
    width: 100%;
  }

  .remove-my-info .form-group {
    width: 100%;
    margin-bottom: -2px;
  }

  .remove-my-info .btn {
    width: 100%;
  }

  .content-box .loader-content .btn-primary {
    font-size: 12px;
    padding: 10px 8px;
  }

}

@media (max-width: 991px) {
  .form-wrapper {
    box-shadow: 0px 9px 18px rgba(0, 0, 0, .22);
  }

  .search-wrapper button {
    display: block;
    width: 100%;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 0;
    box-shadow: 0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.4);
  }

  .search-wrapper .form-group {
    width: 100%;
    margin-bottom: 8px;
    box-shadow: 0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.4);
  }

  .search-wrapper .form-control {
    width: 100% !important;
    display: block !important;
  }

  .footer {
    text-align: center;
  }
}

@media (min-width: 991px) {
  .form-wrapper.phone {
    display: inline-block;
  }
}