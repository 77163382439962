/* Member view styles */

.navbar-nav {
  margin: 0;
}

.navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > a:focus {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .035em;
}

.navbar-default .navbar-nav > li > a:hover {
  background-color: rgba(0, 0, 0, .1);
  color: #fff;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, .3);
}

.navbar-default .navbar-toggle {
  border: 0;
  margin-right: 5px;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: transparent;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
  border-radius: 0;
  width: 28px;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border: 0;
  padding: 0;
  text-align: right;
}

.navsearch-toggle {
  background: transparent;
  border: 0;
  position: absolute;
  right: 51px;
  top: 7px;
}

.navsearch-toggle span {
  color: #fff;
  font-size: 20px;
}

.navsearch-collapse {
  background: #fff;
  padding: 10px 15px 30px;
  border-bottom: 1px solid #dddddd;
}

.navsearch-collapse .form-group {
  margin-bottom: -2px;
  vertical-align: top;
}

.navsearch-collapse .btn {
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dropdown-menu {
  border-radius: 0;
}

.member-wrapper {
  padding-top: 20px;
  padding-bottom: 40px;
  /* background-color: #F8FAFE; */
}

.member-wrapper .container {
  padding: 0;
}

.member-header {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5px;
}

.member-header .no-padd {
  padding-right: 0;
}

.member-header .header-menu button {
  border: none;
  background: transparent;
  font-size: 22px;
  padding: 0;
  font-weight: 500;
}

.member-header .header-menu .add {
  border-top: 1px solid #dddddd;
  margin-top: 5px;
}

.member-header .header-menu .add span {
  margin: -4px -1px 0 -8px;
}

.member-header .header-menu .dropdown-menu li a {
  padding: 7px 20px;
}

.member-header .header-menu .dropdown-menu li .badge {
  color: #666;
  background: #dddddd;
}

.member-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.member-content .btn-sm {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
}

.member-content .btn-group-sm .btn-default {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
  color: #727272;
}

.member-content .btn-group-sm .btn-default:hover {
  color: #fff;
}

.member-content .member-reports {
  padding: 15px;
  border-bottom: 1px solid #dddddd;
}

.member-content .member-reports:last-of-type {
  border-bottom: 0;
}

.member-reports li {
  border: 1px solid #dddddd;
  padding: 25px 20px 15px;
  background: #fff;
  margin-bottom: 8px;
}

.member-reports li h3 {
  font-size: 19px;
  margin-top: 0;
  margin-bottom: 5px;
}

.member-reports li h3 a {
  color: #272727;
}

.member-reports li h4 {
  margin-top: 0;
  font-size: 14px;
  color: #666;
}

.member-reports li .btn-group {
  margin-bottom: 10px;
}

.member-reports li.also {
  background: transparent;
  text-align: left;
  border: 0;
  color: #666;
  padding: 0;
}

.member-reports .archive {
  margin-top: 10px;
}

.member-content {
  box-shadow: 0px 5px 12px rgba(0, 0, 0, .04);
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}

.member-content .main {
  padding: 0;
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  background: #f8f8f8;
}

.member-content .loadmore {
  padding-bottom: 30px;
}

.member-search-header {
  border-bottom: 1px solid #dddddd;
  padding: 0;
}

.member-search-header h1 {
  font-size: 30px;
  margin-top: 5px;
}

.account-wrapper {
  padding-top: 20px;
  background: #f9f9f9;
  padding-bottom: 40px;
}

.account-content .main {
  padding-top: 25px;
  padding-bottom: 30px;
  background: #fff;
}

.account-header {
  padding: 0;
}

.account-header h1 {
  font-size: 24px;
  margin: 0 0 5px 14px;
}

.account-content {
  border: 1px solid #dddddd;
  border-top: 0;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, .04);
}

.account-content .progress {
  border-radius: 0;
}

.account-content .progress-bar {
  background-color: #76b509;
  padding: 0 5px 0 0;
  box-shadow: none;
  text-align: right;
}

.account-header .nav-tabs {
  background: transparent;
  padding: 5px 0 0 5px;
  border-bottom: 1px solid #dddddd;
}

.account-header .nav-tabs > li > a {
  border-radius: 0;
  padding: 10px;
  color: #666;
  border: 0;
}

.account-header .nav-tabs > li > a:hover {
  border-color: transparent;
  background: #fff;
  color: #272727;
}

.account-header .nav-tabs > li.active > a {
  color: #272727;
  background: transparent;
  border-bottom: 2px solid #4851c0;
}

.account-header .nav-tabs > li.active > a:hover, .account-header .nav-tabs > li.active > a:focus {
  color: #272727;
  background: transparent;
  border: 0;
  border-bottom: 2px solid #4851c0;
}

.secondary {
  padding: 20px;
}

.secondary h4 {
  margin-bottom: 20px;
  color: #666;
}

.secondary h5 {
  color: #818181;
  margin-bottom: 3px;
  font-size: 13px;
}

.secondary span.glyphicons {
  margin: -8px -5px 0 -10px;
  font-size: 16px;
}

.secondary p, .secondary ul {
  color: #818181;
  margin-bottom: 20px;
  font-size: 13px;
}

.secondary ul {
  padding-left: 15px;
}

.secondary ul.account-options {
  padding-left: 0;
}

.secondary ul.account-options li {
  margin-bottom: 20px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 15px;
}

.secondary ul.account-options li p {
  margin-bottom: 0;
}

.account-content .package {
  list-style-type: none;
  border: 1px solid #dddddd;
  padding: 10px 15px 15px;
  margin: 0 0 20px;
  font-size: 13px;
  background: #f9f9f9;
}

.account-content .package ul {
  list-style-type: none;
  border: 0;
  padding: 0 0 0 40px;
  margin: 0;
}

.account-content .package h3 {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.account-content .package h3 .price {
  font-size: 12px;
  color: #727272;
  font-weight: 700;
  margin-left: 5px;
}

.account-content .package dl {
  margin: 0;
}

.account-content .package .package-features {
  margin: 0 0 10px;
  list-style-type: disc;
  padding: 0 0 0 15px;
  font-size: 12px;
}

.account-content .main h2 {
  font-size: 18px;
  color: #76b509;
  margin-top: 0;
  margin-left: -14px;
}

.account-content .main h2 span {
  margin: -6px -4px 0 0;
}


.report-wrapper {
  padding: 0;
}

.report-wrapper .report-header h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.report-content .also {
  margin-top: 40px;
  font-size: 12px;
  color: #666;
}

.report-content .also a {
  color: #666;
}

.report-content .also ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.report-content .also h5 {
  font-size: 12px;
  color: #727272;
  margin-bottom: 15px;
}

.report-content .also .nopadd {
  padding: 0;
}

.report-content .also .glyphicons {
  margin: -4px 0 0 -5px;
}

.report-data .panel {
  border-radius: 0;
}

.report-data .panel-body {
  padding: 20px;
}

.report-data h2 {
  text-transform: uppercase;
  color: #666;
  letter-spacing: .025em;
  font-size: 12px;
  margin: 10px 0 20px;
}

.report-data h2 .glyphicons {
  color: #76b509;
  font-size: 18px;
  margin: -9px -4px 0 -7px;
}

.report-data dl dt {
  font-size: 12px;
  color: #727272;
  font-weight: 400;
}

.report-data dl dd {
  font-size: 18px;
  font-weight: 500;
}

.report-data dl img {
  max-width: 100px;
  max-height: 18px;
}

.report-data h6 {
  color: #727272;
  font-weight: 400;
}

.report-data .photos {
  padding: 0;
  list-style-type: none;
  margin-top: 10px;
}

.report-data .photos li {
  display: inline-block;
  margin: 0 0 10px 0;
}

.report-data .photos li a.service,
.report-data .photos li a.service:hover {
  border: none;
}

.report-data .photos li a.photo {
  border: 1px solid #dddddd;
  height: 200px;
  width: 200px;
  overflow: hidden;
  display: block;
  opacity: .7;
}

.report-data .photos li a:hover {
  border: 1px solid #4851c0;
  opacity: 1;
}

.report-data .photos li a img {
  max-width: none;
  max-height: none;
  width: 200px;
}

.report-data .relatives {
  list-style-type: none;
  margin: 0 0 20px 0;
  padding: 0;
}

.report-data .relatives li {
  display: inline-block;
  border: 1px solid #dddddd;
  margin: 0px 0px -2px 0;
  padding: 10px 0;
  background: #f9f9f9;
  width: 49.2%;
  height: 180px;
  overflow-y: hidden;
}

.report-data .relatives li.odd {
  background: #e0e0e0;
}

.report-data .relatives li .relative {
  height: 50px;
  width: 50px;
  display: block;
  text-align: center;
  padding-top: 16px;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
  margin: 0 auto;
  background-size: 50px 50px !important;
}

.relatives li .relative {
  background-image: url(/assets/common/pr1/img/icon-person.png) !important;
}

.report-data .relatives li h6 {
  color: #272727;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0 3px 5px;
}

.report-data .relatives li p {
  font-size: 13px;
  text-align: center;
  color: #666;
  margin: 0 0 5px 6px;
}

.report-data .mapouter {
  margin-bottom: 20px;
}

.report-data .pics img {
  margin-bottom: 20px;
}

.report-data .pics span.logo {
  position: absolute;
  height: 20px;
  width: 20px;
  top: -9px;
  right: 5px;
}

.report-comments h6 {
  margin-bottom: 5px;
}

.report-comments h6 a {
  color: #666;
  text-decoration: none;
}

.report-comments .panel-body {
  padding-bottom: 0;
}

.report-comments .panel-body p {
  font-size: 12px;
  color: #727272;
}

.report-comments .panel-body p.by {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 15px;
}

.report-comments .panel-body .btn {
  box-shadow: none;
  margin-top: 8px;
}

.report-comments .panel-footer {
  margin: 0 -20px;
  padding: 15px 21px;
  border-top: 1px solid #e8e8e8;
}

.report-comments .panel-footer h6 {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
}

.report-comments .panel-footer .badge {
  color: #727272;
  vertical-align: baseline;
  background-color: #e4e4e4;
  margin-left: 3px;
}

.report-comments textarea {
  font-size: 12px;
}

.report-data .upsell {
  list-style-type: none;
  padding: 10px 15px 0px;
  margin: 0;
  font-size: 13px;
}

.report-data .upsell p {
  font-size: 12px;
}

.report-data .upsell ul {
  list-style-type: none;
  border: 0;
  padding: 0 0 0 40px;
  margin: 0;
}

.report-data .upsell h3 {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.report-data .upsell dl {
  margin: 0;
}

.report-data .upsell .upsell-features {
  margin: 0 0 10px;
  list-style-type: disc;
  padding: 0 0 0 15px;
  font-size: 12px;
}

.report-data .bottom-border {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 20px;
}

/* Report nav */
.report-nav {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0;
}

.report-nav li {
  border-bottom: 1px solid #dddddd;
}

.report-nav li:last-of-type {
  border-bottom: 0;
}

.report-nav li a {
  color: #666;
  padding: 6px 0;
  display: block;
  font-size: 13px;
}

.report-nav li a:hover {
  color: #4851c0;
  text-decoration: none;
  padding-left: 2px;
  background: #fff;
}

.report-nav li.active a {
  font-weight: 500;
  color: #272727;
}

.backtotop {
  position: fixed;
  bottom: 4px;
  right: 4px;
}

.backtotop a {
  font-size: 22px;
  color: #4851c0;
}

.backtotop a:hover {
  color: #76b509;
}

/* Media queries */

@media (max-width: 768px) {
  .navsearch-collapse {
    margin: 10px 0 0;
    padding-bottom: 0;
  }

  .navsearch-collapse .form-inline {
    margin-bottom: 30px;
  }

  .member-search-header h1 {
    font-size: 19px;
    margin-top: 10px;
  }

  .account-header .nav-tabs > li > a {
    font-size: 12px;
    padding: 5px;
  }

  .account-header h1 {
    margin-left: 8px;
  }

  .account-content .form-group button {
    width: 100%;
  }

  .account-content .package .btn {
    width: 100%;
  }

  .account-wrapper .secondary {
    border-top: 1px solid #dddddd;
  }

  .report-nav li {
    display: none;
  }

  .report-nav.select-wrap {
    padding: 10px 13px 10px;
    background: #fff;
    margin: 0 0 10px;
    border: 1px solid #dddddd;
  }

  .report-data dl dd {
    font-size: 14px;
  }

  .report-data .photos {
    text-align: center;
  }
}


@media (min-width: 768px) {
  .navsearch-toggle {
    display: none;
  }

  .navsearch-collapse {
    padding: 10px 15px;
  }

  .navsearch-collapse.in {
    overflow-y: auto;
    overflow-y: visible;
  }

  .navsearch-collapse.collapse {
    display: block !important;
    height: auto !important;
    overflow: visible !important;
  }

  .navsearch-collapse .form-control {
    width: 157px;
    height: 38px;
    margin-right: -5px;
  }

  .navsearch-collapse .btn {
    width: 114px;
    height: 38px;
    margin-right: -5px;
    margin-left: 5px;
  }

  .navsearch-collapse .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }

  .account-content .main {
    border-right: 1px solid #dddddd;
    padding: 30px 40px;
  }

  ul.report-nav select {
    display: none;
  }

}
