@import "../../node_modules/@ng-select/ng-select/themes/default.theme.css";

@import "./variables.scss";
@import "./buttons.scss";

// ng-select style
ng-select.ng-invalid.ng-touched .ng-select-container {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}
.ng-select.ng-select-single .ng-select-container {
  border: none;
  outline: none;
  min-height: 32px;
  height: 32px;
  box-shadow: none!important;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  color: $color-primary;
}

.modal-backdrop {
  opacity: 0!important;
}

.switch-xs {
  & > span {
    height: 16px!important;
  }
  .switch-pane {
    width: 35px;
    height: 15px;

    & > span {
      line-height: 17px!important;
    }
  }
  small {
    width: 15px!important;
    height: 15px!important;
    top: 2px;
    right: calc(100% - 18px) !important;
  }

  .checked {
    small {
      right: 2px !important;
    } 
  }
}

.d-flex{
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-center{
  justify-content: center;
}


// .swiper-button-next, .swiper-button-prev{
//   // width: auto !important;
//   &:after{
//     color: #000;
//     // background: url(../assets/ux1/images/arrow-next.png) no-repeat;
//     // content: ' ' !important;
//     // width: 34px;
//     // height: 32px;
//     // font-family: none;
//   }
// }


// .swiper-button-prev:after{

//     background: url(../assets/ux1/images/arrow-next.png) no-repeat;
  
// }

// for NotAvailable lables n/a
.text-muted {
  color: $not-available !important
}

// For Cursor Pointer
.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 767px) {

  /* for search section in header mobile view
  refer app/member/compnents/memberHeader.component.ts
  */ 
  body.disable-scroll {
    overflow: hidden;
  }
}

.btn.disabled, .btn[disabled] {
  cursor: not-allowed !important;
}

#recaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
