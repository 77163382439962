@import "../../../assets/common/bootstrap/bootstrap.css";
@import "../../../assets/common/pr1/css/pr-public.css";
@import "../../../assets/common/pr1/css/pr-members.css";
@import "../../../assets/common/pr1/css/pr-shared.css";
@import "../../../assets/common/pr1/css/animate.css";
@import "../../../assets/common/pr1/css/style.css";

.theme-,
.theme-legacy,
.theme-default,
.theme-default_1_2,
.theme-default_1_3,
.theme-particle_1 {
  .navbar-brand {
    padding-bottom: 0;
    display: flex;
    align-items: center;

    img {
      max-height: 100%;
    }
  }

  .image-checkbox {
    position: absolute;
    left: 100%;
    visibility: hidden;
  }

  .image-checkbox-label {
    height: 12px;
    position: relative;
  }

  .image-checkbox-label::before {
    content: "";
    position: absolute;
    border: 2px solid #767676;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
  }

  .image-checkbox:checked+.image-checkbox-label::after {
    content: "\2713\0020";
    position: absolute;
    top: 1px;
    left: 2px;
    font-size: 10px;
    line-height: 10px;
    color: #767676;
    transition: all .2s;
    font-weight: 900;
    font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
  }

  .ux1-checkbox label {
    padding-left: 16px;
    line-height: 12px;
    font-weight: 400 !important;
  }

  .collapse.in {
    display: block !important;
  }

  #sup {
    label {
      color: #171717;
    }
  }
}

  /*  IE10+  */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    #search-result.sales.name-search #bottomModifySearch button {
      vertical-align: top;
      margin-top: -2px;
    }
  }

