@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");

body {
  margin: 0;

  &:has(.loader__modal) {
    overflow: hidden;
  }
}

.theme-ux_4_0 {
  .color-primary {
    color: #001250;
  }

  .color-accent {
    color: #1ebca9;
  }

  .color-danger {
    color: #ff0000;
  }

  * {
    font-family: "Source Sans Pro", sans-serif;
  }

  &.phone-search {
    * {
      font-family: "Roboto";
      line-height: 1.2;
    }

    .navbar {
      background-color: #474dad;
    }

    .navbar-brand {
      padding: 0;

      img {
        height: 100%;
      }
    }
  }

  footer {
    color: white;
    background: linear-gradient(180deg, #001250 0%, #59658d 100%);
    font-size: 12px;
    line-height: 1.25;

    @media (max-width: 991px) {
      font: 10px;
    }

    @media (max-width: 576px) {
      font: 8px;
    }

    a {
      color: #1ebca9;
      text-decoration: none;
      font-weight: 700;
      transition: color 0.2s;
      outline: 0;

      &:hover,
      &:focus {
        color: white;
      }
    }

    &.light {
      background: white;
      color: #525252;

      a {
        &:hover {
          color: #1ebca9;
        }
      }
    }

    .row {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .footer {
      opacity: 1;
      border-top: none;
      padding: 0;
      padding-top: 24px;

      &__disclaimer {
        margin-top: 18px;

        br {
          display: none;
        }
      }

      &__links {
        list-style-type: none;
        text-align: right;
        padding-left: 0;
        margin-bottom: 0;

        @media (max-width: 576px) {
          text-align: left;
        }

        &--item {
          display: inline-block;
          padding-left: 0.5em;
          padding-right: 0.5em;
          border-left: 2px solid #1ebca9;

          &:first-of-type {
            border-left: none;
          }

          &:last-of-type {
            padding-right: 0;
          }

          @media (max-width: 576px) {
            border-left: none;
            width: 50%;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }

  .bt-accent {
    border-top: 1px solid #1ebca9;
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .page {
    &__content {
      &--title {
        font-size: 48px;
        font-weight: 600;
        line-height: 1.25;
        color: inherit;
        margin: 0;
        margin-bottom: 0.25em;

        @media (max-width: 990px) {
          font-size: 32px;
        }

        @media (max-width: 576px) {
          font-size: 24px;
        }
      }

      &--detail {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.25;
        margin: 0;
        margin-bottom: 0.25em;

        @media (max-width: 990px) {
          font-size: 16px;
        }

        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
    }
  }

  .theme-ux_4_0 {
    .form__submit {
      background: #1ebca9;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      color: white;
      font-weight: 900;
      height: 46px;
      font-size: 24px;
      line-height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: 0;
      padding: 0 1em;
      cursor: pointer;
      transition: opacity 0.2s;
    
      &:hover {
        opacity: 0.8;
      }
    
      @media (max-width: 576px) {
        width: 100%;
      }
    }
    
    .form__input {
      background: white;
      border: 1px solid #dcdcdc;
      color: #525252;
      box-sizing: border-box;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      font-size: 16px;
      line-height: 20px;
      padding: 9px 12px;
      outline: 0;
      transition: all .2s;
    
      &.select {
        appearance: none;
        position: relative;
      }
    
      &.has-error {
        border-color: #ff0000;
        padding: 8px 12px;
        border-width: 2px;
      }
    
      &.has-success {
        border-color: #1ebca9;
        padding: 8px 12px;
        border-width: 2px;
      }
    
      @media (max-width: 576px) {
        width: 100%;
      }
    }
    
    .form__select {
      position: relative;
    
      &:before {
        z-index: 5;
        content: '';
        position: absolute;
        right: 12px;
        top: 16px;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-top: 10px solid #1ebca9;
        pointer-events: none;
      }
      
      &:after {
        z-index: 10;
        content: '';
        position: absolute;
        right: 14px;
        top: 15px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-top: 8px solid white;
        pointer-events: none;
      }
    }
    
    .form__error {
      color: #ff0000;
    }
  }

  @mixin ux4-btn-link($background-color, $color) {
    border: none;
    background: transparent;
    outline: 0;
    color: $color;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    border: 1px solid white;
    border-radius: 10px;
    height: 34px;
    padding: 7px 23px;
    transition: all 0.2s;
  
    &:hover {
      background-color: $color;
      color: $background-color;
    }
  }
  
  .ux4-btn-outline {
    border: none;
    background-color: white;
    outline: 0;
    color: #1ebca9;
    border: 2px solid #1ebca9;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.25;
    padding: 8px;
    transition: all 0.2s;
    border-radius: 10px;
    margin: 0 4px;
    width: 140px;
    cursor: pointer;
  
    &:hover {
      background-color: #1ebca9;
      color: white;
    }
  }
  
  .ux4-btn-accent {
    border: none;
    background: #1ebca9;
    outline: 0;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s;
  
    &:hover {
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    }
  
    &.block {
      width: 100%;
    }
  
    @media (max-width: 576px) {
      &:hover {
        box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.25),
          inset 2px 2px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }  
}
